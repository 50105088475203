import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";

const PricingPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          footerStyle: "style2",
        }}
      >
        <Hero />
        <Content />
        {/* <Team /> */}
      </PageWrapper>
    </>
  );
};
export default PricingPage;
